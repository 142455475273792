/* ../src/components/Header.svelte generated by Svelte v3.19.2 */
import {
	SvelteComponent,
	action_destroyer,
	append,
	attr,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	run_all,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import Logo from "../assets/Logo.svelte";
import { link } from "svelte-spa-router";
import content from "../content";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[3] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[3] = list[i];
	return child_ctx;
}

// (9:12) {:else}
function create_else_block(ctx) {
	let i;

	return {
		c() {
			i = element("i");
			attr(i, "class", "fas fa-bars fa-lg");
		},
		m(target, anchor) {
			insert(target, i, anchor);
		},
		d(detaching) {
			if (detaching) detach(i);
		}
	};
}

// (7:12) {#if open}
function create_if_block_1(ctx) {
	let i;

	return {
		c() {
			i = element("i");
			attr(i, "class", "fas fa-times fa-lg");
		},
		m(target, anchor) {
			insert(target, i, anchor);
		},
		d(detaching) {
			if (detaching) detach(i);
		}
	};
}

// (15:8) {#each menus as menu}
function create_each_block_1(ctx) {
	let a;
	let t_value = /*menu*/ ctx[3].title + "";
	let t;
	let link_action;
	let dispose;

	return {
		c() {
			a = element("a");
			t = text(t_value);
			attr(a, "class", "block px-6");
		},
		m(target, anchor) {
			insert(target, a, anchor);
			append(a, t);
			dispose = action_destroyer(link_action = link.call(null, a, /*menu*/ ctx[3].url()));
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
		},
		d(detaching) {
			if (detaching) detach(a);
			dispose();
		}
	};
}

// (19:4) {#if open}
function create_if_block(ctx) {
	let nav;
	let each_value = /*menus*/ ctx[1];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			nav = element("nav");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(nav, "class", "bg-white fixed flex flex-col z-10 w-screen h-screen font-bold text-lg");
		},
		m(target, anchor) {
			insert(target, nav, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(nav, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*menus*/ 2) {
				each_value = /*menus*/ ctx[1];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(nav, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d(detaching) {
			if (detaching) detach(nav);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (21:12) {#each menus as menu}
function create_each_block(ctx) {
	let a;
	let t_value = /*menu*/ ctx[3].title + "";
	let t;
	let link_action;
	let dispose;

	return {
		c() {
			a = element("a");
			t = text(t_value);
			attr(a, "class", "text-blue pt-10 p-4 hoverr:text-gray");
		},
		m(target, anchor) {
			insert(target, a, anchor);
			append(a, t);
			dispose = action_destroyer(link_action = link.call(null, a, /*menu*/ ctx[3].url()));
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
		},
		d(detaching) {
			if (detaching) detach(a);
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let a;
	let link_action;
	let t0;
	let button;
	let t1;
	let nav;
	let t2;
	let current;
	let dispose;
	const logo = new Logo({});

	function select_block_type(ctx, dirty) {
		if (/*open*/ ctx[0]) return create_if_block_1;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block0 = current_block_type(ctx);
	let each_value_1 = /*menus*/ ctx[1];
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	let if_block1 = /*open*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			a = element("a");
			create_component(logo.$$.fragment);
			t0 = space();
			button = element("button");
			if_block0.c();
			t1 = space();
			nav = element("nav");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t2 = space();
			if (if_block1) if_block1.c();
			attr(button, "class", "md:invisible");
			attr(div0, "class", "flex justify-between items-center w-full");
			attr(nav, "class", "hidden md:flex font-bold");
			attr(div1, "class", "content-wrapper flex justify-between items-center p-8 md:p-12");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, a);
			mount_component(logo, a, null);
			append(div0, t0);
			append(div0, button);
			if_block0.m(button, null);
			append(div1, t1);
			append(div1, nav);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(nav, null);
			}

			append(div1, t2);
			if (if_block1) if_block1.m(div1, null);
			current = true;

			dispose = [
				action_destroyer(link_action = link.call(null, a, "/")),
				listen(button, "click", /*toggle*/ ctx[2])
			];
		},
		p(ctx, [dirty]) {
			if (current_block_type !== (current_block_type = select_block_type(ctx, dirty))) {
				if_block0.d(1);
				if_block0 = current_block_type(ctx);

				if (if_block0) {
					if_block0.c();
					if_block0.m(button, null);
				}
			}

			if (dirty & /*menus*/ 2) {
				each_value_1 = /*menus*/ ctx[1];
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(nav, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}

			if (/*open*/ ctx[0]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(div1, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(logo.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(logo.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			destroy_component(logo);
			if_block0.d();
			destroy_each(each_blocks, detaching);
			if (if_block1) if_block1.d();
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const menus = content.navigation.header;
	let open = false;

	function toggle() {
		$$invalidate(0, open = !open);
	}

	return [open, menus, toggle];
}

class Header extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Header;