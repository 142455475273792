/* ../src/App.svelte generated by Svelte v3.19.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import Router from "svelte-spa-router";
import Header from "./components/Header.svelte";
import Footer from "./components/Footer.svelte";
import Home from "./pages/Home.svelte";
import Notes from "./pages/Notes.svelte";
import About from "./pages/About.svelte";
import Contact from "./pages/Contact.svelte";
import TermsAndConditions from "./pages/TermsAndConditions.svelte";
import PrivacyPolicy from "./pages/PrivacyPolicy.svelte";
import NotFound from "./pages/404.svelte";
import Pricing from "./pages/Pricing.svelte";
import { fly } from "svelte/transition";

function create_fragment(ctx) {
	let div;
	let t0;
	let t1;
	let current;
	const header = new Header({});
	const router = new Router({ props: { routes: /*routes*/ ctx[0] } });
	router.$on("routeLoaded", routing);
	const footer = new Footer({});

	return {
		c() {
			div = element("div");
			create_component(header.$$.fragment);
			t0 = space();
			create_component(router.$$.fragment);
			t1 = space();
			create_component(footer.$$.fragment);
			attr(div, "class", "font-sans antialiased text-gray text-center md:text-left");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(header, div, null);
			append(div, t0);
			mount_component(router, div, null);
			append(div, t1);
			mount_component(footer, div, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(header.$$.fragment, local);
			transition_in(router.$$.fragment, local);
			transition_in(footer.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(header.$$.fragment, local);
			transition_out(router.$$.fragment, local);
			transition_out(footer.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(header);
			destroy_component(router);
			destroy_component(footer);
		}
	};
}

function routing() {
	window.scrollTo(0, 0);
}

function instance($$self) {
	const routes = {
		"/": Home,
		"/notes": Notes,
		"/about": About,
		"/contact": Contact,
		"/terms-and-conditions": TermsAndConditions,
		"/privacy-policy": PrivacyPolicy,
		"/pricing": Pricing,
		"*": NotFound
	};

	return [routes];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;