/* ../src/components/Footer.svelte generated by Svelte v3.19.2 */
import {
	SvelteComponent,
	action_destroyer,
	append,
	attr,
	check_outros,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import Logo from "../assets/Logo.svelte";
import LogoStandalone from "../assets/LogoStandalone.svelte";
import { link } from "svelte-spa-router";
import Badge from "./Badge.svelte";
import content from "../content";

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	return child_ctx;
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[1] = list[i];
	return child_ctx;
}

// (16:28) {#if row.disabled}
function create_if_block(ctx) {
	let current;

	const badge = new Badge({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(badge.$$.fragment);
		},
		m(target, anchor) {
			mount_component(badge, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const badge_changes = {};

			if (dirty & /*$$scope*/ 128) {
				badge_changes.$$scope = { dirty, ctx };
			}

			badge.$set(badge_changes);
		},
		i(local) {
			if (current) return;
			transition_in(badge.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(badge.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(badge, detaching);
		}
	};
}

// (17:32) <Badge>
function create_default_slot(ctx) {
	let t_value = content.coming_soon + "";
	let t;

	return {
		c() {
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (13:20) {#each column.links as row}
function create_each_block_1(ctx) {
	let a;
	let t0_value = /*row*/ ctx[4].title + "";
	let t0;
	let t1;
	let a_class_value;
	let link_action;
	let current;
	let dispose;
	let if_block = /*row*/ ctx[4].disabled && create_if_block(ctx);

	return {
		c() {
			a = element("a");
			t0 = text(t0_value);
			t1 = space();
			if (if_block) if_block.c();
			attr(a, "class", a_class_value = "block pb-2 font-semibold " + (/*row*/ ctx[4].disabled && "text-gray pointer-events-none"));
		},
		m(target, anchor) {
			insert(target, a, anchor);
			append(a, t0);
			append(a, t1);
			if (if_block) if_block.m(a, null);
			current = true;
			dispose = action_destroyer(link_action = link.call(null, a, /*row*/ ctx[4].url()));
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (/*row*/ ctx[4].disabled) if_block.p(ctx, dirty);
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(a);
			if (if_block) if_block.d();
			dispose();
		}
	};
}

// (10:12) {#each content.navigation.footer as column}
function create_each_block(ctx) {
	let nav;
	let p;
	let t0_value = /*column*/ ctx[1].title + "";
	let t0;
	let t1;
	let t2;
	let current;
	let each_value_1 = /*column*/ ctx[1].links;
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			nav = element("nav");
			p = element("p");
			t0 = text(t0_value);
			t1 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t2 = space();
			attr(p, "class", "block pb-4 font-bold text-lg text-gray");
			attr(nav, "class", "flex flex-col md:px-6 py-4 md:w-1/2 lg:w-auto");
		},
		m(target, anchor) {
			insert(target, nav, anchor);
			append(nav, p);
			append(p, t0);
			append(nav, t1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(nav, null);
			}

			append(nav, t2);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*content*/ 0) {
				each_value_1 = /*column*/ ctx[1].links;
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(nav, t2);
					}
				}

				group_outros();

				for (i = each_value_1.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(nav);
			destroy_each(each_blocks, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div4;
	let div2;
	let div0;
	let a;
	let link_action;
	let t0;
	let p0;
	let t2;
	let div1;
	let t3;
	let div3;
	let hr0;
	let t4;
	let t5;
	let hr1;
	let t6;
	let p1;
	let current;
	let dispose;
	const logo = new Logo({});
	let each_value = content.navigation.footer;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	const logostandalone = new LogoStandalone({ props: { class: "mx-8" } });

	return {
		c() {
			div4 = element("div");
			div2 = element("div");
			div0 = element("div");
			a = element("a");
			create_component(logo.$$.fragment);
			t0 = space();
			p0 = element("p");
			p0.textContent = `${content.slogan}`;
			t2 = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t3 = space();
			div3 = element("div");
			hr0 = element("hr");
			t4 = space();
			create_component(logostandalone.$$.fragment);
			t5 = space();
			hr1 = element("hr");
			t6 = space();
			p1 = element("p");
			p1.textContent = `© ${/*year*/ ctx[0]} Inkable GmbH`;
			attr(p0, "class", "py-4");
			attr(div0, "class", "md:w-1/3 lg:w-1/4 flex flex-col items-center md:items-start py-4");
			attr(div1, "class", "md:w-2/3 lg:w-3/4 flex flex-col md:flex-row md:flex-wrap lg:flex-no-wrap justify-between flex-grow");
			attr(div2, "class", "flex flex-col md:flex-row justify-between p-12 max-w-6xl m-auto");
			attr(hr0, "class", "border-1 flex-grow border-gray-bright bg-gray-light rounded-full");
			attr(hr1, "class", "border-1 flex-grow border-gray-bright bg-gray-light rounded-full");
			attr(div3, "class", "flex justify-between items-center w-full max-w-6xl m-auto px-12");
			attr(p1, "class", "text-center text-xs text-gray-light m-8");
			attr(div4, "class", "mb-16 select-none");
		},
		m(target, anchor) {
			insert(target, div4, anchor);
			append(div4, div2);
			append(div2, div0);
			append(div0, a);
			mount_component(logo, a, null);
			append(div0, t0);
			append(div0, p0);
			append(div2, t2);
			append(div2, div1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div1, null);
			}

			append(div4, t3);
			append(div4, div3);
			append(div3, hr0);
			append(div3, t4);
			mount_component(logostandalone, div3, null);
			append(div3, t5);
			append(div3, hr1);
			append(div4, t6);
			append(div4, p1);
			current = true;
			dispose = action_destroyer(link_action = link.call(null, a, "/"));
		},
		p(ctx, [dirty]) {
			if (dirty & /*content*/ 0) {
				each_value = content.navigation.footer;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div1, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(logo.$$.fragment, local);

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			transition_in(logostandalone.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(logo.$$.fragment, local);
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			transition_out(logostandalone.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div4);
			destroy_component(logo);
			destroy_each(each_blocks, detaching);
			destroy_component(logostandalone);
			dispose();
		}
	};
}

function instance($$self) {
	const year = new Date().getFullYear();
	return [year];
}

class Footer extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Footer;