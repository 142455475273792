/* ../src/components/QuestionsAnswer.svelte generated by Svelte v3.19.2 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	space
} from "svelte/internal";

function create_fragment(ctx) {
	let div2;
	let div0;
	let h4;
	let t0;
	let i;
	let i_class_value;
	let t1;
	let div1;
	let p;
	let div1_class_value;
	let dispose;

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			h4 = element("h4");
			t0 = space();
			i = element("i");
			t1 = space();
			div1 = element("div");
			p = element("p");
			attr(h4, "class", "font-semibold text-blue md:text-xl truncate");
			attr(i, "class", i_class_value = "fas fa-angle-up fa-lg text-blue rotate-" + (/*selected*/ ctx[0] ? 0 : 180));
			attr(div0, "class", "flex justify-between items-center cursor-pointer select-none bg-blue-bright rounded-full p-4 px-8");
			attr(p, "class", "py-4 md:px-8 break-words");
			attr(div1, "class", div1_class_value = !/*selected*/ ctx[0] && "hidden");
			attr(div2, "class", "w-full my-2");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div0, h4);
			h4.innerHTML = /*question*/ ctx[1];
			append(div0, t0);
			append(div0, i);
			append(div2, t1);
			append(div2, div1);
			append(div1, p);
			p.innerHTML = /*answer*/ ctx[2];
			dispose = listen(div0, "click", /*select*/ ctx[3]);
		},
		p(ctx, [dirty]) {
			if (dirty & /*question*/ 2) h4.innerHTML = /*question*/ ctx[1];;

			if (dirty & /*selected*/ 1 && i_class_value !== (i_class_value = "fas fa-angle-up fa-lg text-blue rotate-" + (/*selected*/ ctx[0] ? 0 : 180))) {
				attr(i, "class", i_class_value);
			}

			if (dirty & /*answer*/ 4) p.innerHTML = /*answer*/ ctx[2];;

			if (dirty & /*selected*/ 1 && div1_class_value !== (div1_class_value = !/*selected*/ ctx[0] && "hidden")) {
				attr(div1, "class", div1_class_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div2);
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { question = "" } = $$props;
	let { answer = "" } = $$props;
	let { selected = false } = $$props;

	function select() {
		$$invalidate(0, selected = !selected);
	}

	$$self.$set = $$props => {
		if ("question" in $$props) $$invalidate(1, question = $$props.question);
		if ("answer" in $$props) $$invalidate(2, answer = $$props.answer);
		if ("selected" in $$props) $$invalidate(0, selected = $$props.selected);
	};

	return [selected, question, answer, select];
}

class QuestionsAnswer extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { question: 1, answer: 2, selected: 0 });
	}
}

export default QuestionsAnswer;