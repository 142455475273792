/* ../src/components/Pill.svelte generated by Svelte v3.19.2 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";

import background from "../assets/pieces.svg";

function create_fragment(ctx) {
	let section;
	let div;
	let h2;
	let t0;
	let t1;
	let p;
	let t2;
	let t3;
	let a;
	let t4_value = /*button*/ ctx[2].text + "";
	let t4;
	let a_href_value;

	return {
		c() {
			section = element("section");
			div = element("div");
			h2 = element("h2");
			t0 = text(/*heading*/ ctx[0]);
			t1 = space();
			p = element("p");
			t2 = text(/*paragraph*/ ctx[1]);
			t3 = space();
			a = element("a");
			t4 = text(t4_value);
			attr(h2, "class", "text-white text-2xl font-bold");
			attr(p, "class", "text-white mt-4 mb-6");
			attr(a, "class", "button-white");
			attr(a, "href", a_href_value = /*button*/ ctx[2].url);
			attr(div, "class", "md:w-1/2");
			attr(section, "class", "relative flex rounded-large p-12 md:p-12 bg-blue pieces-background");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			append(section, div);
			append(div, h2);
			append(h2, t0);
			append(div, t1);
			append(div, p);
			append(p, t2);
			append(div, t3);
			append(div, a);
			append(a, t4);
		},
		p(ctx, [dirty]) {
			if (dirty & /*heading*/ 1) set_data(t0, /*heading*/ ctx[0]);
			if (dirty & /*paragraph*/ 2) set_data(t2, /*paragraph*/ ctx[1]);
			if (dirty & /*button*/ 4 && t4_value !== (t4_value = /*button*/ ctx[2].text + "")) set_data(t4, t4_value);

			if (dirty & /*button*/ 4 && a_href_value !== (a_href_value = /*button*/ ctx[2].url)) {
				attr(a, "href", a_href_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(section);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { heading = "" } = $$props;
	let { paragraph = "" } = $$props;
	let { button = { text: "", url: "" } } = $$props;

	$$self.$set = $$props => {
		if ("heading" in $$props) $$invalidate(0, heading = $$props.heading);
		if ("paragraph" in $$props) $$invalidate(1, paragraph = $$props.paragraph);
		if ("button" in $$props) $$invalidate(2, button = $$props.button);
	};

	return [heading, paragraph, button];
}

class Pill extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { heading: 0, paragraph: 1, button: 2 });
	}
}

export default Pill;