/* ../src/pages/Contact.svelte generated by Svelte v3.19.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import Main from "../components/Main.svelte";
import LogoStandalone from "../assets/LogoStandalone.svelte";
import content from "../content";

function create_default_slot(ctx) {
	let header;
	let h1;
	let t1;
	let h2;
	let t3;
	let section;
	let t4;
	let form;
	let label0;
	let t6;
	let input0;
	let t7;
	let label1;
	let t9;
	let input1;
	let t10;
	let label2;
	let t12;
	let textarea;
	let t13;
	let input2;
	let input2_value_value;
	let current;

	const logostandalone = new LogoStandalone({
			props: {
				class: "hidden lg:block",
				height: "128",
				width: "128",
				viewBox: "0 0 26 26"
			}
		});

	return {
		c() {
			header = element("header");
			h1 = element("h1");
			h1.textContent = `${content.contact.title}`;
			t1 = space();
			h2 = element("h2");
			h2.textContent = `${content.contact.subtitle}`;
			t3 = space();
			section = element("section");
			create_component(logostandalone.$$.fragment);
			t4 = space();
			form = element("form");
			label0 = element("label");
			label0.textContent = `${content.contact.form.name}`;
			t6 = space();
			input0 = element("input");
			t7 = space();
			label1 = element("label");
			label1.textContent = `${content.contact.form.email}`;
			t9 = space();
			input1 = element("input");
			t10 = space();
			label2 = element("label");
			label2.textContent = `${content.contact.form.message}`;
			t12 = space();
			textarea = element("textarea");
			t13 = space();
			input2 = element("input");
			attr(h1, "class", "page-title");
			attr(h2, "class", "page-subtitle");
			attr(header, "class", "page-header");
			attr(label0, "for", "name");
			attr(input0, "id", "name");
			attr(input0, "type", "text");
			attr(label1, "for", "email");
			attr(input1, "id", "email");
			attr(input1, "type", "email");
			attr(label2, "for", "message");
			attr(textarea, "id", "message");
			attr(textarea, "cols", "6");
			attr(input2, "type", "submit");
			input2.value = input2_value_value = content.contact.form.submit;
			attr(input2, "class", "button");
			attr(form, "class", "w-full lg:w-2/5 flex flex-col");
			attr(section, "class", "flex justify-between items-center py-8");
		},
		m(target, anchor) {
			insert(target, header, anchor);
			append(header, h1);
			append(header, t1);
			append(header, h2);
			insert(target, t3, anchor);
			insert(target, section, anchor);
			mount_component(logostandalone, section, null);
			append(section, t4);
			append(section, form);
			append(form, label0);
			append(form, t6);
			append(form, input0);
			append(form, t7);
			append(form, label1);
			append(form, t9);
			append(form, input1);
			append(form, t10);
			append(form, label2);
			append(form, t12);
			append(form, textarea);
			append(form, t13);
			append(form, input2);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(logostandalone.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(logostandalone.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(header);
			if (detaching) detach(t3);
			if (detaching) detach(section);
			destroy_component(logostandalone);
		}
	};
}

function create_fragment(ctx) {
	let current;

	const main = new Main({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(main.$$.fragment);
		},
		m(target, anchor) {
			mount_component(main, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const main_changes = {};

			if (dirty & /*$$scope*/ 1) {
				main_changes.$$scope = { dirty, ctx };
			}

			main.$set(main_changes);
		},
		i(local) {
			if (current) return;
			transition_in(main.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(main.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(main, detaching);
		}
	};
}

class Contact extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Contact;