/* ../src/components/PricingTable.svelte generated by Svelte v3.19.2 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_each,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space,
	text
} from "svelte/internal";

import { link } from "svelte-spa-router";
import content from "../content";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[3] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[3] = list[i];
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[3] = list[i];
	return child_ctx;
}

// (8:12) {#each content.pricing.basic.features as feature}
function create_each_block_2(ctx) {
	let div;
	let i;
	let i_class_value;
	let t0;
	let p;
	let t1_value = /*feature*/ ctx[3].text + "";
	let t1;
	let t2;

	return {
		c() {
			div = element("div");
			i = element("i");
			t0 = space();
			p = element("p");
			t1 = text(t1_value);
			t2 = space();
			attr(i, "class", i_class_value = "fas fa-" + /*feature*/ ctx[3].icon + " text-blue mr-4");
			attr(div, "class", "flex items-center my-2");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, i);
			append(div, t0);
			append(div, p);
			append(p, t1);
			append(div, t2);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (28:20) {#each content.pricing.basic.features as feature}
function create_each_block_1(ctx) {
	let div;
	let i;
	let i_class_value;
	let t0;
	let p;
	let t1_value = /*feature*/ ctx[3].text + "";
	let t1;
	let t2;

	return {
		c() {
			div = element("div");
			i = element("i");
			t0 = space();
			p = element("p");
			t1 = text(t1_value);
			t2 = space();
			attr(i, "class", i_class_value = "fas fa-" + /*feature*/ ctx[3].icon + " text-blue mr-4");
			attr(div, "class", "flex items-center my-2");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, i);
			append(div, t0);
			append(div, p);
			append(p, t1);
			append(div, t2);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (43:20) {#each content.pricing.additional.features as feature}
function create_each_block(ctx) {
	let div;
	let i;
	let i_class_value;
	let t0;
	let p;
	let t1_value = /*feature*/ ctx[3].text + "";
	let t1;
	let t2;

	return {
		c() {
			div = element("div");
			i = element("i");
			t0 = space();
			p = element("p");
			t1 = text(t1_value);
			t2 = space();
			attr(i, "class", i_class_value = "fas fa-" + /*feature*/ ctx[3].icon + " text-blue mr-4");
			attr(div, "class", "flex items-center my-2");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, i);
			append(div, t0);
			append(div, p);
			append(p, t1);
			append(div, t2);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment(ctx) {
	let section;
	let div1;
	let h20;
	let t1;
	let p0;
	let t3;
	let span0;
	let t5;
	let h30;
	let t7;
	let div0;
	let t8;
	let a0;
	let t10;
	let div8;
	let div2;
	let p1;
	let t12;
	let div7;
	let div4;
	let h21;
	let t14;
	let p2;
	let t16;
	let span1;
	let t18;
	let h31;
	let t20;
	let div3;
	let t21;
	let a1;
	let t23;
	let div6;
	let h22;
	let t25;
	let p3;
	let t27;
	let span2;
	let t29;
	let h32;
	let t31;
	let div5;
	let t32;
	let a2;
	let each_value_2 = content.pricing.basic.features;
	let each_blocks_2 = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks_2[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	let each_value_1 = content.pricing.basic.features;
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	let each_value = content.pricing.additional.features;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			section = element("section");
			div1 = element("div");
			h20 = element("h2");
			h20.textContent = `${/*starter*/ ctx[0].name}`;
			t1 = space();
			p0 = element("p");
			p0.textContent = `${/*starter*/ ctx[0].price}`;
			t3 = space();
			span0 = element("span");
			span0.textContent = `${/*starter*/ ctx[0].per}`;
			t5 = space();
			h30 = element("h3");
			h30.textContent = `${content.pricing.basic.title}`;
			t7 = space();
			div0 = element("div");

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				each_blocks_2[i].c();
			}

			t8 = space();
			a0 = element("a");
			a0.textContent = `${/*starter*/ ctx[0].button.text}`;
			t10 = space();
			div8 = element("div");
			div2 = element("div");
			p1 = element("p");
			p1.textContent = `${content.coming_soon}`;
			t12 = space();
			div7 = element("div");
			div4 = element("div");
			h21 = element("h2");
			h21.textContent = `${/*pro*/ ctx[1].name}`;
			t14 = space();
			p2 = element("p");
			p2.textContent = `${/*pro*/ ctx[1].price}`;
			t16 = space();
			span1 = element("span");
			span1.textContent = `${/*pro*/ ctx[1].per}`;
			t18 = space();
			h31 = element("h3");
			h31.textContent = `${content.pricing.basic.title}`;
			t20 = space();
			div3 = element("div");

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t21 = space();
			a1 = element("a");
			a1.textContent = `${/*pro*/ ctx[1].button.text}`;
			t23 = space();
			div6 = element("div");
			h22 = element("h2");
			h22.textContent = `${/*education*/ ctx[2].name}`;
			t25 = space();
			p3 = element("p");
			p3.textContent = `${/*education*/ ctx[2].price}`;
			t27 = space();
			span2 = element("span");
			span2.textContent = `${/*education*/ ctx[2].per}`;
			t29 = space();
			h32 = element("h3");
			h32.textContent = `${content.pricing.additional.title}`;
			t31 = space();
			div5 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t32 = space();
			a2 = element("a");
			a2.textContent = `${/*education*/ ctx[2].button.text}`;
			attr(h20, "class", "uppercase font-bold");
			attr(p0, "class", "text-3xl font-bold text-blue mt-4");
			attr(span0, "class", "text-lg text-gray-light mb-4");
			attr(h30, "class", "font-bold");
			attr(div0, "class", "my-4");
			attr(a0, "class", "button");
			attr(div1, "class", "flex flex-col md:w-1/3 items-center bg-blue-bright rounded-large p-8 mr-2 pieces-background");
			attr(p1, "class", "uppercase select-none bg-gray rounded-full text-white px-4 py-1");
			attr(div2, "class", "flex items-center justify-center absolute z-10 w-full h-full");
			attr(h21, "class", "uppercase font-bold");
			attr(p2, "class", "text-3xl font-bold text-blue mt-4");
			attr(span1, "class", "text-lg text-gray-light mb-4");
			attr(h31, "class", "font-bold");
			attr(div3, "class", "my-4");
			attr(a1, "class", "button-white disabled");
			attr(div4, "class", "flex flex-col md:w-1/2 items-center p-8 opacity-50");
			attr(h22, "class", "uppercase font-bold");
			attr(p3, "class", "text-3xl font-bold text-blue mt-4");
			attr(span2, "class", "text-lg text-gray-light mb-4");
			attr(h32, "class", "font-bold");
			attr(div5, "class", "my-4");
			attr(a2, "class", "button-white disabled");
			attr(div6, "class", "flex flex-col md:w-1/2 items-center p-8 opacity-50");
			attr(div7, "class", "flex justify-between");
			attr(div8, "class", "relative md:w-2/3 ml-2 rounded-large");
			attr(section, "class", "flex overflow-x-scroll md:overflow-x-hidden justify-between whitespace-no-wrap");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			append(section, div1);
			append(div1, h20);
			append(div1, t1);
			append(div1, p0);
			append(div1, t3);
			append(div1, span0);
			append(div1, t5);
			append(div1, h30);
			append(div1, t7);
			append(div1, div0);

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				each_blocks_2[i].m(div0, null);
			}

			append(div1, t8);
			append(div1, a0);
			append(section, t10);
			append(section, div8);
			append(div8, div2);
			append(div2, p1);
			append(div8, t12);
			append(div8, div7);
			append(div7, div4);
			append(div4, h21);
			append(div4, t14);
			append(div4, p2);
			append(div4, t16);
			append(div4, span1);
			append(div4, t18);
			append(div4, h31);
			append(div4, t20);
			append(div4, div3);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(div3, null);
			}

			append(div4, t21);
			append(div4, a1);
			append(div7, t23);
			append(div7, div6);
			append(div6, h22);
			append(div6, t25);
			append(div6, p3);
			append(div6, t27);
			append(div6, span2);
			append(div6, t29);
			append(div6, h32);
			append(div6, t31);
			append(div6, div5);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div5, null);
			}

			append(div6, t32);
			append(div6, a2);
		},
		p(ctx, [dirty]) {
			if (dirty & /*content*/ 0) {
				each_value_2 = content.pricing.basic.features;
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks_2[i]) {
						each_blocks_2[i].p(child_ctx, dirty);
					} else {
						each_blocks_2[i] = create_each_block_2(child_ctx);
						each_blocks_2[i].c();
						each_blocks_2[i].m(div0, null);
					}
				}

				for (; i < each_blocks_2.length; i += 1) {
					each_blocks_2[i].d(1);
				}

				each_blocks_2.length = each_value_2.length;
			}

			if (dirty & /*content*/ 0) {
				each_value_1 = content.pricing.basic.features;
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						each_blocks_1[i].m(div3, null);
					}
				}

				for (; i < each_blocks_1.length; i += 1) {
					each_blocks_1[i].d(1);
				}

				each_blocks_1.length = each_value_1.length;
			}

			if (dirty & /*content*/ 0) {
				each_value = content.pricing.additional.features;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div5, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(section);
			destroy_each(each_blocks_2, detaching);
			destroy_each(each_blocks_1, detaching);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self) {
	const { starter, pro, education } = content.pricing;
	return [starter, pro, education];
}

class PricingTable extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default PricingTable;