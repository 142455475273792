/* ../src/components/Splash.svelte generated by Svelte v3.19.2 */
import {
	SvelteComponent,
	append,
	assign,
	attr,
	detach,
	element,
	exclude_internal_props,
	get_spread_update,
	init,
	insert,
	noop,
	safe_not_equal,
	set_attributes,
	set_data,
	space,
	svg_element,
	text
} from "svelte/internal";

function create_fragment(ctx) {
	let section;
	let div;
	let h2;
	let t0;
	let t1;
	let p;
	let t2;
	let t3;
	let a;
	let t4_value = /*button*/ ctx[2].text + "";
	let t4;
	let a_href_value;
	let t5;
	let svg;
	let path0;
	let circle0;
	let path1;
	let circle1;
	let circle2;
	let circle3;
	let circle4;
	let path2;
	let path3;
	let path4;
	let g0;
	let path5;
	let g1;
	let path6;
	let g2;
	let path7;

	let section_levels = [
		/*$$props*/ ctx[3],
		{
			class: "flex flex-col-reverse md:flex-row items-center"
		}
	];

	let section_data = {};

	for (let i = 0; i < section_levels.length; i += 1) {
		section_data = assign(section_data, section_levels[i]);
	}

	return {
		c() {
			section = element("section");
			div = element("div");
			h2 = element("h2");
			t0 = text(/*heading*/ ctx[0]);
			t1 = space();
			p = element("p");
			t2 = text(/*paragraph*/ ctx[1]);
			t3 = space();
			a = element("a");
			t4 = text(t4_value);
			t5 = space();
			svg = svg_element("svg");
			path0 = svg_element("path");
			circle0 = svg_element("circle");
			path1 = svg_element("path");
			circle1 = svg_element("circle");
			circle2 = svg_element("circle");
			circle3 = svg_element("circle");
			circle4 = svg_element("circle");
			path2 = svg_element("path");
			path3 = svg_element("path");
			path4 = svg_element("path");
			g0 = svg_element("g");
			path5 = svg_element("path");
			g1 = svg_element("g");
			path6 = svg_element("path");
			g2 = svg_element("g");
			path7 = svg_element("path");
			attr(h2, "class", "text-blue text-3xl font-bold");
			attr(p, "class", "mt-4 mb-6");
			attr(a, "class", "button");
			attr(a, "href", a_href_value = /*button*/ ctx[2].url);
			attr(div, "class", "md:w-2/5 py-8");
			attr(path0, "fill", "#5186D9");
			attr(path0, "fill-rule", "evenodd");
			attr(path0, "d", "M625 223.401c0-107.623-87.377-195-195-195s-195 87.377-195 195c0 48.53 17.767 92.944 47.145 127.085l-.145-.085c28.375 34.522 3.97 49.812-12.296 52.636-.547.075-1.091.157-1.633.246-.363.046-.72.085-1.071.118l.08.05c-31.855 5.685-56.08 33.74-56.08 67.45 0 37.806 30.47 68.5 68 68.5s68-30.694 68-68.5a68.784 68.784 0 00-3.656-22.199c-3.193-17.092 9.698-40.225 45.32-34.699A195.453 195.453 0 00430 418.401c107.623 0 195-87.377 195-195z");
			attr(circle0, "fill", "#FFF");
			attr(circle0, "fill-opacity", ".1");
			attr(circle0, "cx", "474.5");
			attr(circle0, "cy", "177.901");
			attr(circle0, "r", "95.5");
			attr(path1, "fill", "#FFF");
			attr(path1, "fill-rule", "evenodd");
			attr(path1, "d", "M427.5 143.401c12.341 0 22.36 10.02 22.36 22.362v116.276c0 12.343-10.019 22.362-22.36 22.362s-22.362-10.019-22.362-22.362V165.763c0-12.343 10.02-22.362 22.362-22.362zm0 11.5c6.17 0 11.18 5.01 11.18 11.18v115.64c0 6.17-5.01 11.18-11.18 11.18-6.171 0-11.18-5.01-11.18-11.18V166.08c0-6.17 5.009-11.18 11.18-11.18zM369.362 201.54c12.341 0 22.36 10.02 22.36 22.361v58.14c0 12.34-10.019 22.36-22.36 22.36-12.343 0-22.362-10.02-22.362-22.36V223.9c0-12.341 10.019-22.36 22.362-22.36zm0 11.181c6.17 0 11.18 5.01 11.18 11.18v58.14c0 6.17-5.01 11.18-11.18 11.18-6.172 0-11.181-5.01-11.181-11.18V223.9c0-6.17 5.01-11.18 11.18-11.18zM485.634 143.401c12.347 0 22.366 10.02 22.366 22.362V223.9c0 12.341-10.019 22.362-22.366 22.362-12.337 0-22.356-10.02-22.356-22.362v-58.138c0-12.343 10.019-22.362 22.356-22.362zm0 11.5c6.173 0 11.189 5.01 11.189 11.18v57.82c0 6.171-5.016 11.18-11.19 11.18-6.165 0-11.174-5.009-11.174-11.18v-57.82c0-6.17 5.01-11.18 11.175-11.18z");
			attr(circle1, "stroke", "#5186D9");
			attr(circle1, "stroke-width", "13");
			attr(circle1, "cx", "190.5");
			attr(circle1, "cy", "284.901");
			attr(circle1, "fill", "none");
			attr(circle1, "r", "28.5");
			attr(circle1, "opacity", ".8");
			attr(circle2, "stroke", "#5186D9");
			attr(circle2, "stroke-width", "10");
			attr(circle2, "cx", "603.5");
			attr(circle2, "cy", "44.901");
			attr(circle2, "fill", "none");
			attr(circle2, "r", "14.5");
			attr(circle2, "opacity", ".8");
			attr(circle3, "stroke", "#5186D9");
			attr(circle3, "stroke-width", "10");
			attr(circle3, "cx", "19.5");
			attr(circle3, "cy", "468.901");
			attr(circle3, "fill", "none");
			attr(circle3, "r", "14.5");
			attr(circle3, "opacity", ".8");
			attr(circle4, "stroke", "#5186D9");
			attr(circle4, "stroke-width", "13");
			attr(circle4, "cx", "624.5");
			attr(circle4, "cy", "439.901");
			attr(circle4, "fill", "none");
			attr(circle4, "r", "28.5");
			attr(circle4, "opacity", ".8");
			attr(path2, "fill", "#FFF");
			attr(path2, "fill-opacity", ".1");
			attr(path2, "fill-rule", "evenodd");
			attr(path2, "d", "M302 468.901c0 15.73-12.77 28.5-28.5 28.5s-28.5-12.77-28.5-28.5 12.77-28.5 28.5-28.5 28.5 12.77 28.5 28.5z");
			attr(path3, "fill", "#5186D9");
			attr(path3, "fill-rule", "evenodd");
			attr(path3, "d", "M638.206 316.768c19.204 0 34.794 15.489 34.794 34.566 0 19.078-15.59 34.567-34.794 34.567-19.203 0-34.793-15.489-34.793-34.567s15.59-34.566 34.793-34.566z");
			attr(path4, "fill", "#5186D9");
			attr(path4, "fill-rule", "evenodd");
			attr(path4, "d", "M577 349.81c18.54-15.25 26.159-.509 26.92 7.624l25.144-39.395c-8.89 3.304-23.366-3.05-19.556-23.638L577 349.809z");
			attr(path5, "fill", "#5186D9");
			attr(path5, "fill-rule", "evenodd");
			attr(path5, "d", "M483.737 475.359c6.813 8.192 5.694 20.374-2.498 27.187l-38.591 32.097c-8.193 6.813-20.375 5.694-27.188-2.498-6.814-8.192-5.694-20.374 2.498-27.188l38.59-32.096c8.193-6.814 20.375-5.694 27.189 2.498zm-7.634 6.349c3.407 4.096 2.847 10.187-1.25 13.593l-38.378 31.92c-4.096 3.407-10.187 2.848-13.594-1.248-3.406-4.096-2.847-10.187 1.249-13.594l38.38-31.92c4.096-3.407 10.187-2.848 13.593 1.249z");
			attr(g0, "opacity", ".8");
			attr(path6, "fill", "#5186D9");
			attr(path6, "fill-rule", "evenodd");
			attr(path6, "d", "M111.34 17.317C117.081 5.187 131.59 0 143.72 5.741l57.144 27.045c12.13 5.74 17.317 20.25 11.576 32.38-5.741 12.13-20.25 17.317-32.38 11.576l-57.144-27.045c-12.13-5.74-17.317-20.25-11.576-32.38zm11.303 5.35c2.87-6.065 10.125-8.659 16.19-5.788l56.83 26.896c6.065 2.87 8.658 10.125 5.788 16.19-2.871 6.065-10.125 8.66-16.19 5.788l-56.83-26.896c-6.065-2.87-8.659-10.125-5.788-16.19z");
			attr(g1, "opacity", ".8");
			attr(path7, "fill", "#5186D9");
			attr(path7, "fill-rule", "evenodd");
			attr(path7, "d", "M715.074 79.794c15.8 2.615 26.51 17.565 23.89 33.366l-12.32 74.435c-2.61 15.8-17.56 26.506-33.36 23.892-15.8-2.615-26.51-17.565-23.89-33.366l12.31-74.435c2.62-15.801 17.57-26.506 33.37-23.892zm-2.44 14.723c7.9 1.308 13.26 8.783 11.95 16.683l-12.25 74.026c-1.31 7.9-8.78 13.253-16.68 11.946-7.9-1.307-13.26-8.782-11.95-16.683l12.25-74.026c1.31-7.9 8.78-13.253 16.68-11.946z");
			attr(g2, "opacity", ".8");
			attr(svg, "class", "md:w-3/5");
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg, "viewBox", "0 0 750 600");
			set_attributes(section, section_data);
		},
		m(target, anchor) {
			insert(target, section, anchor);
			append(section, div);
			append(div, h2);
			append(h2, t0);
			append(div, t1);
			append(div, p);
			append(p, t2);
			append(div, t3);
			append(div, a);
			append(a, t4);
			append(section, t5);
			append(section, svg);
			append(svg, path0);
			append(svg, circle0);
			append(svg, path1);
			append(svg, circle1);
			append(svg, circle2);
			append(svg, circle3);
			append(svg, circle4);
			append(svg, path2);
			append(svg, path3);
			append(svg, path4);
			append(svg, g0);
			append(g0, path5);
			append(svg, g1);
			append(g1, path6);
			append(svg, g2);
			append(g2, path7);
		},
		p(ctx, [dirty]) {
			if (dirty & /*heading*/ 1) set_data(t0, /*heading*/ ctx[0]);
			if (dirty & /*paragraph*/ 2) set_data(t2, /*paragraph*/ ctx[1]);
			if (dirty & /*button*/ 4 && t4_value !== (t4_value = /*button*/ ctx[2].text + "")) set_data(t4, t4_value);

			if (dirty & /*button*/ 4 && a_href_value !== (a_href_value = /*button*/ ctx[2].url)) {
				attr(a, "href", a_href_value);
			}

			set_attributes(section, get_spread_update(section_levels, [
				dirty & /*$$props*/ 8 && /*$$props*/ ctx[3],
				{
					class: "flex flex-col-reverse md:flex-row items-center"
				}
			]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(section);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { heading = "" } = $$props;
	let { paragraph = "" } = $$props;
	let { button = { text: "", url: "" } } = $$props;

	$$self.$set = $$new_props => {
		$$invalidate(3, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		if ("heading" in $$new_props) $$invalidate(0, heading = $$new_props.heading);
		if ("paragraph" in $$new_props) $$invalidate(1, paragraph = $$new_props.paragraph);
		if ("button" in $$new_props) $$invalidate(2, button = $$new_props.button);
	};

	$$props = exclude_internal_props($$props);
	return [heading, paragraph, button, $$props];
}

class Splash extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { heading: 0, paragraph: 1, button: 2 });
	}
}

export default Splash;