/* ../src/pages/Home.svelte generated by Svelte v3.19.2 */
import {
	SvelteComponent,
	assign,
	create_component,
	destroy_component,
	get_spread_object,
	get_spread_update,
	init,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "svelte/internal";

import Pill from "../components/Pill.svelte";
import Splash from "../components/Splash.svelte";
import Main from "../components/Main.svelte";
import content from "../content";

function create_default_slot(ctx) {
	let current;
	const splash_spread_levels = [content.home.splash];
	let splash_props = {};

	for (let i = 0; i < splash_spread_levels.length; i += 1) {
		splash_props = assign(splash_props, splash_spread_levels[i]);
	}

	const splash = new Splash({ props: splash_props });

	return {
		c() {
			create_component(splash.$$.fragment);
		},
		m(target, anchor) {
			mount_component(splash, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const splash_changes = (dirty & /*content*/ 0)
			? get_spread_update(splash_spread_levels, [get_spread_object(content.home.splash)])
			: {};

			splash.$set(splash_changes);
		},
		i(local) {
			if (current) return;
			transition_in(splash.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(splash.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(splash, detaching);
		}
	};
}

function create_fragment(ctx) {
	let current;

	const main = new Main({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(main.$$.fragment);
		},
		m(target, anchor) {
			mount_component(main, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const main_changes = {};

			if (dirty & /*$$scope*/ 1) {
				main_changes.$$scope = { dirty, ctx };
			}

			main.$set(main_changes);
		},
		i(local) {
			if (current) return;
			transition_in(main.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(main.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(main, detaching);
		}
	};
}

class Home extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Home;