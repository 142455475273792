/* ../src/assets/LogoStandalone.svelte generated by Svelte v3.19.2 */
import {
	SvelteComponent,
	append,
	assign,
	attr,
	detach,
	exclude_internal_props,
	get_spread_update,
	init,
	insert,
	noop,
	safe_not_equal,
	set_svg_attributes,
	svg_element
} from "svelte/internal";

function create_fragment(ctx) {
	let svg;
	let path;

	let svg_levels = [
		{ xmlns: "http://www.w3.org/2000/svg" },
		{ height: "26" },
		{ width: "26" },
		/*$$props*/ ctx[0]
	];

	let svg_data = {};

	for (let i = 0; i < svg_levels.length; i += 1) {
		svg_data = assign(svg_data, svg_levels[i]);
	}

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "fill", "#E0E0E0");
			attr(path, "fill-rule", "evenodd");
			attr(path, "d", "M13 0a3.613 3.613 0 013.611 3.611v18.778A3.613 3.613 0 0113 26a3.613 3.613 0 01-3.611-3.611V3.611A3.613 3.613 0 0113 0zm0 1.857c.997 0 1.806.809 1.806 1.806v18.674a1.807 1.807 0 01-3.612 0V3.663c0-.997.809-1.806 1.806-1.806zM3.611 9.389A3.613 3.613 0 017.222 13v9.389A3.613 3.613 0 013.611 26 3.613 3.613 0 010 22.389V13a3.613 3.613 0 013.611-3.611zm0 1.805c.997 0 1.806.81 1.806 1.806v9.389a1.806 1.806 0 01-3.611 0V13c0-.996.81-1.806 1.805-1.806zM22.389 0A3.613 3.613 0 0126 3.611V13a3.613 3.613 0 01-3.611 3.611A3.613 3.613 0 0118.778 13V3.611A3.613 3.613 0 0122.389 0zm0 1.857c.996 0 1.805.809 1.805 1.806V13a1.806 1.806 0 01-3.611 0V3.663c0-.997.809-1.806 1.806-1.806z");
			set_svg_attributes(svg, svg_data);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		p(ctx, [dirty]) {
			set_svg_attributes(svg, get_spread_update(svg_levels, [
				{ xmlns: "http://www.w3.org/2000/svg" },
				{ height: "26" },
				{ width: "26" },
				dirty & /*$$props*/ 1 && /*$$props*/ ctx[0]
			]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	$$self.$set = $$new_props => {
		$$invalidate(0, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
	};

	$$props = exclude_internal_props($$props);
	return [$$props];
}

class LogoStandalone extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default LogoStandalone;