/* ../src/pages/Pricing.svelte generated by Svelte v3.19.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import Main from "../components/Main.svelte";
import PricingTable from "../components/PricingTable.svelte";
import content from "../content";

function create_default_slot(ctx) {
	let header;
	let h1;
	let t1;
	let h2;
	let t3;
	let current;
	const pricingtable = new PricingTable({});

	return {
		c() {
			header = element("header");
			h1 = element("h1");
			h1.textContent = `${content.pricing.title}`;
			t1 = space();
			h2 = element("h2");
			h2.textContent = `${content.pricing.subtitle}`;
			t3 = space();
			create_component(pricingtable.$$.fragment);
			attr(h1, "class", "page-title");
			attr(h2, "class", "page-subtitle");
			attr(header, "class", "page-header");
		},
		m(target, anchor) {
			insert(target, header, anchor);
			append(header, h1);
			append(header, t1);
			append(header, h2);
			insert(target, t3, anchor);
			mount_component(pricingtable, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(pricingtable.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(pricingtable.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(header);
			if (detaching) detach(t3);
			destroy_component(pricingtable, detaching);
		}
	};
}

function create_fragment(ctx) {
	let current;

	const main = new Main({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(main.$$.fragment);
		},
		m(target, anchor) {
			mount_component(main, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const main_changes = {};

			if (dirty & /*$$scope*/ 1) {
				main_changes.$$scope = { dirty, ctx };
			}

			main.$set(main_changes);
		},
		i(local) {
			if (current) return;
			transition_in(main.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(main.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(main, detaching);
		}
	};
}

class Pricing extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Pricing;