import Polyglot from 'node-polyglot';
import phrases from './locales/en.yml';

export const polyglot = new Polyglot({
    phrases,
    allowMissing: true,
});

function apply(phrases, history, t = {}) {
    Object.keys(phrases).forEach(key => {
        let cache;
        Object.defineProperty(t, key, {
            enumerable: true,
            get() {
                if (cache) return cache;
                const property = history ? history + '.' + key : key;
                if (typeof phrases[key] === 'object') {
                    const t = Array.isArray(phrases[key]) ? [] : {};
                    return cache = apply(phrases[key], property, t);
                } else {
                    cache = (options) => polyglot.t(property, options);
                    cache.toString = () => polyglot.t(property);
                    cache.valueOf = () => polyglot.t(property);
                    return cache;
                }
            }
        });
    });
    return t;
}

export default apply(phrases);
