/* ../src/pages/TermsAndConditions.svelte generated by Svelte v3.19.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import Main from "../components/Main.svelte";
import content from "../content";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[0] = list[i];
	return child_ctx;
}

// (8:4) {#each content.legal.terms.chapters as chapter}
function create_each_block(ctx) {
	let section;
	let h1;
	let raw0_value = /*chapter*/ ctx[0].title + "";
	let t0;
	let p;
	let raw1_value = /*chapter*/ ctx[0].description + "";
	let t1;

	return {
		c() {
			section = element("section");
			h1 = element("h1");
			t0 = space();
			p = element("p");
			t1 = space();
			attr(h1, "class", "text-blue text-2xl md:text-2xl mb-4");
			attr(section, "class", "my-8");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			append(section, h1);
			h1.innerHTML = raw0_value;
			append(section, t0);
			append(section, p);
			p.innerHTML = raw1_value;
			append(section, t1);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(section);
		}
	};
}

// (1:0) <Main>
function create_default_slot(ctx) {
	let header;
	let h1;
	let t1;
	let h2;
	let t3;
	let p;
	let raw_value = content.legal.terms.description + "";
	let t4;
	let each_1_anchor;
	let each_value = content.legal.terms.chapters;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			header = element("header");
			h1 = element("h1");
			h1.textContent = `${content.legal.terms.title}`;
			t1 = space();
			h2 = element("h2");
			h2.textContent = `${content.legal.terms.subtitle}`;
			t3 = space();
			p = element("p");
			t4 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
			attr(h1, "class", "page-title");
			attr(h2, "class", "page-subtitle");
			attr(header, "class", "page-header");
			attr(p, "class", "mt-8");
		},
		m(target, anchor) {
			insert(target, header, anchor);
			append(header, h1);
			append(header, t1);
			append(header, h2);
			insert(target, t3, anchor);
			insert(target, p, anchor);
			p.innerHTML = raw_value;
			insert(target, t4, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*content*/ 0) {
				each_value = content.legal.terms.chapters;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d(detaching) {
			if (detaching) detach(header);
			if (detaching) detach(t3);
			if (detaching) detach(p);
			if (detaching) detach(t4);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
		}
	};
}

function create_fragment(ctx) {
	let current;

	const main = new Main({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(main.$$.fragment);
		},
		m(target, anchor) {
			mount_component(main, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const main_changes = {};

			if (dirty & /*$$scope*/ 8) {
				main_changes.$$scope = { dirty, ctx };
			}

			main.$set(main_changes);
		},
		i(local) {
			if (current) return;
			transition_in(main.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(main.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(main, detaching);
		}
	};
}

class TermsAndConditions extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default TermsAndConditions;