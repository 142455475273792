/* ../src/components/BulletPointList.svelte generated by Svelte v3.19.2 */
import {
	SvelteComponent,
	append,
	assign,
	attr,
	destroy_each,
	detach,
	element,
	exclude_internal_props,
	get_spread_update,
	init,
	insert,
	noop,
	safe_not_equal,
	set_attributes,
	set_data,
	space,
	text
} from "svelte/internal";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[2] = list[i];
	return child_ctx;
}

// (2:4) {#each points as point}
function create_each_block(ctx) {
	let div2;
	let div0;
	let i;
	let i_class_value;
	let t0;
	let div1;
	let h3;
	let t1_value = /*point*/ ctx[2].title + "";
	let t1;
	let t2;
	let p;
	let t3_value = /*point*/ ctx[2].description + "";
	let t3;
	let t4;

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			i = element("i");
			t0 = space();
			div1 = element("div");
			h3 = element("h3");
			t1 = text(t1_value);
			t2 = space();
			p = element("p");
			t3 = text(t3_value);
			t4 = space();
			attr(i, "class", i_class_value = "fas fa-" + /*point*/ ctx[2].icon + " fa-lg text-blue");
			attr(div0, "class", "rounded-full mb-2 lg:mb-0 min-h-12 min-w-12 h-12 w-12 bg-blue-bright flex justify-center items-center");
			attr(h3, "class", "text-blue text-2xl font-semibold");
			attr(p, "class", "mb-8 mt-2");
			attr(div1, "class", "md:mx-5 lg:mx-0 lg:ml-8");
			attr(div2, "class", "flex flex-col items-center text-center lg:flex-row lg:text-left lg:items-start");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div0, i);
			append(div2, t0);
			append(div2, div1);
			append(div1, h3);
			append(h3, t1);
			append(div1, t2);
			append(div1, p);
			append(p, t3);
			append(div2, t4);
		},
		p(ctx, dirty) {
			if (dirty & /*points*/ 1 && i_class_value !== (i_class_value = "fas fa-" + /*point*/ ctx[2].icon + " fa-lg text-blue")) {
				attr(i, "class", i_class_value);
			}

			if (dirty & /*points*/ 1 && t1_value !== (t1_value = /*point*/ ctx[2].title + "")) set_data(t1, t1_value);
			if (dirty & /*points*/ 1 && t3_value !== (t3_value = /*point*/ ctx[2].description + "")) set_data(t3, t3_value);
		},
		d(detaching) {
			if (detaching) detach(div2);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let each_value = /*points*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	let div_levels = [
		/*$$props*/ ctx[1],
		{
			class: "flex flex-col md:flex-row lg:flex-col " + /*$$props*/ ctx[1].class
		}
	];

	let div_data = {};

	for (let i = 0; i < div_levels.length; i += 1) {
		div_data = assign(div_data, div_levels[i]);
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			set_attributes(div, div_data);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*points*/ 1) {
				each_value = /*points*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			set_attributes(div, get_spread_update(div_levels, [
				dirty & /*$$props*/ 2 && /*$$props*/ ctx[1],
				dirty & /*$$props*/ 2 && {
					class: "flex flex-col md:flex-row lg:flex-col " + /*$$props*/ ctx[1].class
				}
			]));
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { points = [] } = $$props;

	$$self.$set = $$new_props => {
		$$invalidate(1, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		if ("points" in $$new_props) $$invalidate(0, points = $$new_props.points);
	};

	$$props = exclude_internal_props($$props);
	return [points, $$props];
}

class BulletPointList extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { points: 0 });
	}
}

export default BulletPointList;