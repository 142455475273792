/* ../src/components/Main.svelte generated by Svelte v3.19.2 */
import {
	SvelteComponent,
	add_render_callback,
	assign,
	create_in_transition,
	create_out_transition,
	create_slot,
	detach,
	element,
	exclude_internal_props,
	get_slot_changes,
	get_slot_context,
	get_spread_update,
	init,
	insert,
	safe_not_equal,
	set_attributes,
	transition_in,
	transition_out
} from "svelte/internal";

import { fly } from "svelte/transition";

function create_fragment(ctx) {
	let main;
	let main_intro;
	let main_outro;
	let current;
	const default_slot_template = /*$$slots*/ ctx[2].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[1], null);

	let main_levels = [
		/*$$props*/ ctx[0],
		{
			class: "content-wrapper " + /*$$props*/ ctx[0].class
		}
	];

	let main_data = {};

	for (let i = 0; i < main_levels.length; i += 1) {
		main_data = assign(main_data, main_levels[i]);
	}

	return {
		c() {
			main = element("main");
			if (default_slot) default_slot.c();
			set_attributes(main, main_data);
		},
		m(target, anchor) {
			insert(target, main, anchor);

			if (default_slot) {
				default_slot.m(main, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot && default_slot.p && dirty & /*$$scope*/ 2) {
				default_slot.p(get_slot_context(default_slot_template, ctx, /*$$scope*/ ctx[1], null), get_slot_changes(default_slot_template, /*$$scope*/ ctx[1], dirty, null));
			}

			set_attributes(main, get_spread_update(main_levels, [
				dirty & /*$$props*/ 1 && /*$$props*/ ctx[0],
				dirty & /*$$props*/ 1 && {
					class: "content-wrapper " + /*$$props*/ ctx[0].class
				}
			]));
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);

			add_render_callback(() => {
				if (main_outro) main_outro.end(1);
				if (!main_intro) main_intro = create_in_transition(main, fly, { y: 50, duration: 500 });
				main_intro.start();
			});

			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			if (main_intro) main_intro.invalidate();
			main_outro = create_out_transition(main, fly, { duration: 0 });
			current = false;
		},
		d(detaching) {
			if (detaching) detach(main);
			if (default_slot) default_slot.d(detaching);
			if (detaching && main_outro) main_outro.end();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots = {}, $$scope } = $$props;

	$$self.$set = $$new_props => {
		$$invalidate(0, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		if ("$$scope" in $$new_props) $$invalidate(1, $$scope = $$new_props.$$scope);
	};

	$$props = exclude_internal_props($$props);
	return [$$props, $$scope, $$slots];
}

class Main extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Main;