/* ../src/pages/404.svelte generated by Svelte v3.19.2 */
import {
	SvelteComponent,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import Main from "../components/Main.svelte";
import content from "../content";

function create_default_slot(ctx) {
	let h1;
	let t1;
	let h20;
	let t3;
	let h21;

	return {
		c() {
			h1 = element("h1");
			h1.textContent = `${content.error.description}`;
			t1 = space();
			h20 = element("h2");
			h20.textContent = `${content.error.title}`;
			t3 = space();
			h21 = element("h2");
			h21.textContent = `– ${content.error.quote}`;
			attr(h1, "class", "text-2xl md:text-4xl font-bold");
			attr(h20, "class", "text-xl md:text-2xl mt-8 text-blue font-bold");
			attr(h21, "class", "text-lg mt-2");
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, t1, anchor);
			insert(target, h20, anchor);
			insert(target, t3, anchor);
			insert(target, h21, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(h20);
			if (detaching) detach(t3);
			if (detaching) detach(h21);
		}
	};
}

function create_fragment(ctx) {
	let current;

	const main = new Main({
			props: {
				class: "flex flex-col items-center justify-center my-64 md:py-32",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(main.$$.fragment);
		},
		m(target, anchor) {
			mount_component(main, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const main_changes = {};

			if (dirty & /*$$scope*/ 1) {
				main_changes.$$scope = { dirty, ctx };
			}

			main.$set(main_changes);
		},
		i(local) {
			if (current) return;
			transition_in(main.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(main.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(main, detaching);
		}
	};
}

class _404 extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default _404;