/* ../src/pages/About.svelte generated by Svelte v3.19.2 */
import {
	SvelteComponent,
	append,
	assign,
	attr,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	get_spread_object,
	get_spread_update,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import Pill from "../components/Pill.svelte";
import Main from "../components/Main.svelte";
import content from "../content";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[0] = list[i];
	return child_ctx;
}

// (6:4) {#each content.about.paragraphs as paragraph}
function create_each_block(ctx) {
	let section;
	let t_value = /*paragraph*/ ctx[0] + "";
	let t;

	return {
		c() {
			section = element("section");
			t = text(t_value);
			attr(section, "class", "my-8 md:m-12");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			append(section, t);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(section);
		}
	};
}

// (1:0) <Main>
function create_default_slot(ctx) {
	let header;
	let h1;
	let t1;
	let h2;
	let t3;
	let t4;
	let current;
	let each_value = content.about.paragraphs;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const pill_spread_levels = [content.home.pill];
	let pill_props = {};

	for (let i = 0; i < pill_spread_levels.length; i += 1) {
		pill_props = assign(pill_props, pill_spread_levels[i]);
	}

	const pill = new Pill({ props: pill_props });

	return {
		c() {
			header = element("header");
			h1 = element("h1");
			h1.textContent = `${content.about.title}`;
			t1 = space();
			h2 = element("h2");
			h2.textContent = `${content.about.subtitle}`;
			t3 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t4 = space();
			create_component(pill.$$.fragment);
			attr(h1, "class", "page-title");
			attr(h2, "class", "page-subtitle");
			attr(header, "class", "page-header");
		},
		m(target, anchor) {
			insert(target, header, anchor);
			append(header, h1);
			append(header, t1);
			append(header, h2);
			insert(target, t3, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, t4, anchor);
			mount_component(pill, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*content*/ 0) {
				each_value = content.about.paragraphs;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(t4.parentNode, t4);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			const pill_changes = (dirty & /*content*/ 0)
			? get_spread_update(pill_spread_levels, [get_spread_object(content.home.pill)])
			: {};

			pill.$set(pill_changes);
		},
		i(local) {
			if (current) return;
			transition_in(pill.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(pill.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(header);
			if (detaching) detach(t3);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(t4);
			destroy_component(pill, detaching);
		}
	};
}

function create_fragment(ctx) {
	let current;

	const main = new Main({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(main.$$.fragment);
		},
		m(target, anchor) {
			mount_component(main, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const main_changes = {};

			if (dirty & /*$$scope*/ 8) {
				main_changes.$$scope = { dirty, ctx };
			}

			main.$set(main_changes);
		},
		i(local) {
			if (current) return;
			transition_in(main.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(main.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(main, detaching);
		}
	};
}

class About extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default About;