/* ../src/components/Badge.svelte generated by Svelte v3.19.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_slot,
	detach,
	element,
	get_slot_changes,
	get_slot_context,
	init,
	insert,
	safe_not_equal,
	transition_in,
	transition_out
} from "svelte/internal";

function add_css() {
	var style = element("style");
	style.id = "svelte-457d29-style";
	style.textContent = "p.svelte-457d29{font-size:10px;white-space:nowrap;text-wrap:none}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let p;
	let current;
	const default_slot_template = /*$$slots*/ ctx[1].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[0], null);

	return {
		c() {
			p = element("p");
			if (default_slot) default_slot.c();
			attr(p, "class", "inline rounded-full px-2 mx-1 absolute text-blue bg-gray-bright svelte-457d29");
		},
		m(target, anchor) {
			insert(target, p, anchor);

			if (default_slot) {
				default_slot.m(p, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot && default_slot.p && dirty & /*$$scope*/ 1) {
				default_slot.p(get_slot_context(default_slot_template, ctx, /*$$scope*/ ctx[0], null), get_slot_changes(default_slot_template, /*$$scope*/ ctx[0], dirty, null));
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(p);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots = {}, $$scope } = $$props;

	$$self.$set = $$props => {
		if ("$$scope" in $$props) $$invalidate(0, $$scope = $$props.$$scope);
	};

	return [$$scope, $$slots];
}

class Badge extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-457d29-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Badge;